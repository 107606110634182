import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import axios from 'axios'


export default class LandingOther extends Component {

  constructor(props) {
    super(props);
    this.state = {
      check: "0"
    };
  }

  componentDidMount() {
    localStorage.setItem('token', this.props.match.params.t);
    axios.get(`https://ibank.allinonebet.fun/ipp/`)
      .then(res => {
        let ClientIp = res.data.ip;
        var hostname = window.location.hostname
        var hour = (new Date()).getHours()

        if (hostname === "member.sbo.bz") {
          hostname = hostname.split(".")
          hostname = hostname[1] + "." + hostname[2]
        }else{
          hostname = hostname.split(".")
          hostname = hostname[1] + "." + hostname[2]
        }

        var data = sha256(hostname + ClientIp + hour).toString()
        console.log(data)
	console.log(this.props.match.params.key)
        if (this.props.match.params.key !== data) {
          localStorage.removeItem('token');
          this.setState({
            check:"1"
          })
        }else{
          window.location.reload(false)
        }
      })
  }

  render() {
    // if(this.state.check === "1"){
    //   return <Redirect to="/dashboard" />;
    // }else{
    //   return <Redirect to="/login" />;
    // }
   
    if(this.state.check === "1"){
      return <Redirect to="/login" />;
    }else{
      return <Redirect to="/dashboard" />;
    }
  }
}
