import React, { Component } from 'react';
import './mobilebar.css';
import { NavLink } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import History from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { connect } from 'react-redux';
import { logout } from '../../../Actions/AuthAction';

class mobilebar extends Component {
  render() {
    // const useStyle = makeStyles({
    //     root: {
    //         width: 500,
    //     }
    // })
    return (
      <div>
        <nav className="mobilebar">
          <ul className="text-white">
            <li>
              <NavLink to="/dashboard">
                <HomeIcon />
                <br></br>
                <span>หน้าแรก</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/deposit">
                <PaymentIcon />
                <br></br>
                <span>เเจ้งฝาก</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/withdraw">
                <AccountBalanceWalletIcon />
                <br></br>
                <span>แจ้งถอน</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/history">
                <History />
                <br></br>
                <span>History</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/#" onClick={this.props.logout}>
                <ExitToAppIcon />
                <br></br>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
export default connect(null, { logout })(mobilebar);

// import React, { Component } from 'react'

// import { makeStyles } from '@material-ui/core/styles';
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import HistoryIcon from '@material-ui/icons/History';
// import SettingsIcon from '@material-ui/icons/Settings';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import { Link } from 'react-router-dom';

// const useStyles = makeStyles({
//     root: {
//         background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//         borderRadius: 3,
//         border: 0,
//         color: "white",
//         height: 56,
//         boxShadow: "0px 0px 7px rgba(0,0,0,0.5)",
//         bottom: 0,
//         position: "fixed",
//         width: "100%",
//         left: 0 ,
//     },
//   });

//   export default function LabelBottomNavigation() {
//     const classes = useStyles();
//     const [value, setValue] = React.useState('recents');

//     const handleChange = (event, newValue) => {
//       setValue(newValue);
//     };

//     return (
//         <>
//       <BottomNavigation value={value} onChange={handleChange} className={classes.root}>
//         <BottomNavigationAction label="Dashboard" component={Link} to ={"/dashboard"} value="dashboard" icon={<DashboardIcon />} />
//         <BottomNavigationAction label="History" component={Link} to ={"/history"} value="history" icon={<HistoryIcon />} />
//         <BottomNavigationAction label="Transaction" component={Link} to ={"/dashboard"} value="transaction" icon={<AccountBalanceWalletIcon />} />
//         <BottomNavigationAction label="Setting" component={Link} to ={"/dashboard"} value="setting" icon={<SettingsIcon />} />
//         <BottomNavigationAction label="Exit" component={Link} to="/#" value="exit" icon={< ExitToAppIcon />} />
//  />} />
//       </BottomNavigation>
//       </>
//     );
//   }
