import {
  OTP_SUCCESS,
  OTP_FAIL,
  OTP_VALIDATION_SUCCESS,
  OTP_VALIDATION_FAIL,
} from '../Actions/types';

const initialState = {
  dataRegister: null,
  dataRegisterFail: null,
  dataValidate: null,
  dataFail: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OTP_SUCCESS:
      return { ...state, dataRegister: payload };
      case OTP_FAIL:
        return { ...state, dataRegister: payload }
    case OTP_VALIDATION_SUCCESS:
      return { ...state, dataValidate: payload };
    case OTP_VALIDATION_FAIL:
      return { ...state, dataValidate: null, dataFail: payload  };
    default:
      return state;
  }
}
