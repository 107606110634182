import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGame } from '../../../Actions/GameAction';
// import { getBank, getBankAccount } from '../../../Actions/BankAction';
import { Link } from 'react-router-dom';

class Tranfer extends Component {
  componentDidMount() {
    this.props.getGame();
  }
  checkwaiting() {
    if (this.props.game?.gameAccounts[0].gameUsername === 'waiting') {
      return 'รอการยืนยัน'
    } else {
      return this.props.game?.gameAccounts[0].gameUsername
    }
  }
  render() {
    // console.log(this.props.game);
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                การใช้งาน
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">Username SBOBET</div>
                    {/* <div>{this.props.game?.gameAccounts[0].gameUsername}</div> */}
                    <div>{this.checkwaiting()}</div>
                  </div>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">Password SBOBET</div>
                    <div>{this.props.game?.gameAccounts[0].gamePassword}</div>
                  </div>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <Link to="/deposit" className="btn btn-success col-12">
                    แจ้งฝาก
                  </Link>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <Link to="/withdraw" className="btn btn-warning col-12">
                    แจ้งถอน
                  </Link>
                </li>
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  game: state.game.dataGame?.datas,
});

export default connect(mapStateToProps, {
  getGame,
})(Tranfer);
