import axios from 'axios';
import {
  GET_BANK_SUCCESS,
  GET_BANK_FAIL,
  GET_BANK_WITHDRAW_SUCCESS,
  GET_BANK_WITHDRAW_FAIL,
} from './types';

export const getBank = (gameUsername, systemName) => async dispatch => {
  const config = {
    headers: {
      authorization: localStorage.token,
    },
    params: {
      gameUsername: gameUsername,
      systemName: systemName,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }bank/depositAccount/${gameUsername}/${systemName}`,
      config,
    );
    dispatch({
      type: GET_BANK_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_BANK_FAIL,
    });
  }
};

export const getBankAccount = () => async dispatch => {
  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }bank/getbankaccount`, config);
    // console.log(res.data);
    dispatch({
      type: GET_BANK_WITHDRAW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    // console.log('error api');
    dispatch({
      type: GET_BANK_WITHDRAW_FAIL,
    });
  }
};
