import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getHistory } from '../../Actions/CreditAction';
// import nextLOGO from "../../LOGO/next.png";
import CircularIndeterminate from '../layout/loading';
import CircularProgress from '@material-ui/core/CircularProgress';

class HistoryWallet extends Component {
  
  componentDidMount() {
    this.props.getHistory();
  }

  changeTHAI(data) {
    if (data.creditTransfer.source === 'deposit') {
      return 'ฝากเงิน';
    } else {
      return 'ถอนเงิน';
    }
  }
  changeColor(data) {
    if (data.creditTransfer.source === 'deposit') {
      return 'col-6 col-md-3 text-center text-success';
    } else {
      return 'col-6 col-md-3 text-center text-danger';
    }
  }

  changeWordStatus(data) {
    if (data.creditTransfer.status === 'Waiting') {
      return (
        <div className="col-12">
          <div className="row justify-content-center">
            <div>
              <CircularProgress size={24}/>
            </div>
            <div className="pt-2 ml-3 textSize">กำลังดำเนินการ</div>
          </div>
        </div>
      );
    } else if (
      data.creditTransfer.status === 'Success' &&
      data.creditTransfer.source === 'deposit'
    ) {
      return (
        <div className="col-12">
          <div className="row justify-content-center">
            <div>เติมเงินสำเร็จ</div>
          </div>
        </div>
      );
    } else if (
      data.creditTransfer.status === 'Success' &&
      data.creditTransfer.source === 'sbo'
    ) {
      return (
        <div className="col-12">
          <div className="row justify-content-center">
            <div>โอนเงินสำเร็จ</div>
          </div>
        </div>
      );
    } else if (
      data.creditTransfer.status === 'Bypass_Success' &&
      data.creditTransfer.source === 'deposit'
    ) {
      return (
        <div className="col-12">
          <div className="row justify-content-center">
            <div>เติมสำเร็จ</div>
          </div>
        </div>
      );
    } else if (
      data.creditTransfer.status === 'Bypass_Success' &&
      data.creditTransfer.source === 'sbo'
    ) {
      return (
        <div className="col-12">
          <div className="row justify-content-center textSize">
            <div>ตัดเครดิตสำเร็จ กำลังโอน</div>
          </div>
        </div>
      );
    }
  }

  showHistory() {
    return (
      this.props?.credit?.dataHistoryCredit?.datas
        // .slice(0, 10)
        .map((data, i) => {
          return (
            <li className="list-group-item" key={i}>
              <div className="row py-1 mt-3 ">
                <div className=" col-md-2 text-center text-uppercase hidden">
                  {/* <h6>{data.creditTransfer.source}</h6> */}
                  <h6>{this.changeTHAI(data)}</h6>
                </div>
                {/* <div className="col-2 col-md-2 text-center">
                <img src={nextLOGO} className="mb-1"></img>
              </div> */}
                {/* <div className="col-5 col-md-2 text-center text-uppercase">
                <h6>{data.creditTransfer.destination}</h6>
              </div> */}
                <div className="col-6 col-md-3 text-center">
                  {this.changeWordStatus(data)}
                </div>
                <div className={this.changeColor(data)}>
                  <h6> {data.creditTransfer.amount}</h6>
                </div>

                <div className="col-12 col-md-3 text-center ">
                  <div>
                    <h6 className='textSize'>
                      วันที่{' '}
                      {new Date(
                        data.creditTransfer.timeStamp_command,
                      ).toLocaleString()}
                    </h6>
                  </div>
                </div>
                {/* <div>
                    <h6>เวลาเข้าระบบ : {time_ONEforALL}</h6>
                  </div> */}
              </div>
            </li>
          );
        })
    );
  }

  render() {
    return this.props ? (
      <div className=" d-flex justify-content-center">
        <div className="col-10 col-md-9 ">
          <div className="card">
            <div className="card-header text-muted font-weight-bold">
              HISTORY
            </div>
            <ul className="list-group list-group-flush">
              {this.props?.credit?.dataHistoryCredit ? (
                this.showHistory()
              ) : (
                  <CircularIndeterminate/>
                )}
            </ul>
          </div>
        </div>
      </div>
    ) : (
        <div>Loading ...</div>
      );
  }
}

const mapStateToProps = state => ({
  credit: state.credit,
});

export default connect(mapStateToProps, { getHistory })(HistoryWallet);
