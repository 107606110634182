import React, { Component } from 'react';
import { login } from '../../Actions/AuthAction';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Register from '../register/Register';
import PropTypes from 'prop-types';
import './login.css';
import CloseRegister from '../CloseRegister'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  showHidePassword = () => {
    var password = this.refs.showhide;
    if (password.type === 'password') {
      password.type = 'text';
    } else {
      password.type = 'password';
    }
  };

  render() {
    if (this.props.auth.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
      {/*<CloseRegister/>*/}
        <div className="container mt-5 rounded color-border">
          <div className="row justify-content-center">
            <div className="col-auto mt-5">
              <h1 className="color">LOGIN</h1>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-auto mt-3">
              <h3 className="color">{window.location.hostname}</h3>
            </div>
          </div>
          <hr className="border-info" />
          <form className="form" onSubmit={e => this.onSubmit(e)}>
            <div className="row justify-content-center ">
              <div className="col-auto mt-3">
                <svg
                  className="bi bi-people-circle"
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 008 15a6.987 6.987 0 005.468-2.63z" />
                  <path
                    fillRule="evenodd"
                    d="M8 9a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M8 1a7 7 0 100 14A7 7 0 008 1zM0 8a8 8 0 1116 0A8 8 0 010 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="col-auto mt-3">
                <input
                  className=" input-type"
                  type="text"
                  placeholder=" เบอร์โทรศัพท์"
                  onChange={e => this.onChange(e)}
                  value={this.state.username}
                  name="username"
                ></input>
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-auto mt-3 ">
                <svg
                  className="bi bi-lock-fill"
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="11" height="9" x="2.5" y="7" rx="2" />
                  <path
                    fillRule="evenodd"
                    d="M4.5 4a3.5 3.5 0 117 0v3h-1V4a2.5 2.5 0 00-5 0v3h-1V4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="col-auto mt-3 ">
                <input
                  className=" input-type"
                  id="showhide"
                  ref="showhide"
                  type="password"
                  placeholder=" รหัสผ่าน"
                  onChange={e => this.onChange(e)}
                  value={this.state.password}
                  name="password"
                ></input>
                <div className="text-muted font-weight-light mt-2">
                  <input
                    type="checkbox"
                    onClick={() => this.showHidePassword()}
                  />{' '}
                  Show Password
                </div>
                <div className="text mt-2 color">
                  {/* <a type="button">ลืมรหัสผ่าน </a> 
                  | */}
                  {/* <a type="button">  เปลี่ยนรหัสผ่าน</a> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="text-danger">
                ** หากลูกค้าเป็นลูกค้าเก่าสามารถใช้เลขที่บัญชีเป็นรหัสผ่านได้เลย
                **
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-9 col-sm-11 col-md-3 mt-4">
                <button
                  className="btn rounded-pill w-100 color-button text-white"
                  type="submit"
                >
                  ล็อกอิน
                </button>
              </div>
            </div>
          </form>
          <div className="row justify-content-center mb-5">
            <div className="col-9 col-sm-11 col-md-3 mt-1">
              <Register />
            </div>
          </div>
          <div className="row justify-content-center mb-5">
            <div className="col-12 text-center ">
              <a className="text-danger" href="https://lin.ee/chVsWT0">
                ** ติดปัญหากรุณาติอต่อ คลิ๊ก **
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, { login })(Login);
