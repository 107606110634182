import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePass } from '../../Actions/SettingAction';

class ChangePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
      show: true,
    };
  }
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(
      this.state.oldpassword,
      this.state.newpassword,
      this.state.confirmpassword,
    );
  };
  onSubmit = e => {
    e.preventDefault();
    console.log(
      this.state.oldpassword,
      this.state.newpassword,
      this.state.confirmpassword,
    );
    if (this.state.newpassword === this.state.confirmpassword) {
      this.props.changePass(this.state.oldpassword, this.state.newpassword);
    } else
      this.setState({
        show: false,
      });
  };

  render() {
    return (
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <form className="form" onSubmit={e => this.onSubmit(e)}>
            <div hidden={this.state.show} className="text-danger">
              <span>*รหัสผ่านกับยืนยันรหัสผ่าน กรอกไม่ตรงกัน</span>
            </div>
            <span>Old Password</span>
            <div>
              <input
                className="rounded form-control"
                type="password"
                value={this.state.oldpassword}
                name="oldpassword"
                onChange={e => this.onChange(e)}
                required={true}
              ></input>
            </div>
            <span className="mt-2">New Password</span>
            <div>
              <input
                className="rounded form-control"
                type="password"
                value={this.state.newpassword}
                name="newpassword"
                minLength="6"
                onChange={e => this.onChange(e)}
                required={true}
              ></input>
            </div>
            <span className="mt-2">Confirm Password</span>
            <div>
              <input
                className="rounded form-control"
                type="password"
                value={this.state.confirmpassword}
                name="confirmpassword"
                minLength="6"
                onChange={e => this.onChange(e)}
                required={true}
              ></input>
            </div>
            <button className="mt-2 btn btn-primary " type="submit button">
              Confirm
            </button>
          </form>
        </li>
      </ul>
    );
  }
}
export default connect(null, { changePass })(ChangePass);
