import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as device from 'react-device-detect';
import { getGame, gameForDeposit } from '../../Actions/GameAction';
import { getBank, getBankAccount } from '../../Actions/BankAction';

import SCB_PIC from '../../LOGO/bank/scb com.png';
import K_PIC from '../../LOGO/bank/ka com.png';
import KTB_PIC from '../../LOGO/bank/ktb com.png';
import Down from '../../LOGO/Down.png';

import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './deposit.css';

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      slHour: new Date(),
      txtMoney: '',
      rdPro: 'no',
      chosenPromotion: 'noPro',
      tempTime: '',
      tempHour: new Date().getHours().toString(),
      tempMinute: new Date().getMinutes().toString(),
      stepForLoad: 0,
    };
  }

  async componentDidMount() {
    this.props.getBankAccount();
    await this.props.getGame();
    await this.props.getBank(
      this.props.game?.gameAccounts[0].gameUsername,
      this.props.game?.gameAccounts[0].systemName,
    );
    // console.log(this.props)
  }

  warningTime() {
    return (
      <pre>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center text-success">
              ฟรีค่าทำเนียมการโอนเงินทุกธนาคาร
            </div>
            {this.checkBank()}
          </div>
        </div>
      </pre>
    );
  }

  checkBank() {
    if (this.props?.bank?.bankname === 'กรุงไทย') {
      return (
        <div className="col-12 text-center text-danger">
          23.00-03.00 กรุณาแจ้งเวลาที่โอนเงินด้วย
        </div>
      );
    }
  }

  bank() {
    if (this.props?.bank?.bankname === 'กสิกรไทย') {
      if (window.innerWidth > 770) {
        return (
          <button
            className="btn col-12"
            style={{
              background: '#3ca638',
              borderRadius: '15px',
            }}
            onClick={() =>
              window.open(
                'https://online.kasikornbankgroup.com/K-Online/',
                '_blank',
              )
            }
          >
            <img src={K_PIC} className="border-0" alt="K+"></img>
          </button>
        );
      } else {
        return (
          <button
            className="btn"
            style={{
              background: '#3ca638',
              borderRadius: '15px',
            }}
            onClick={() =>
              window.open(
                'https://online.kasikornbankgroup.com/K-Online/',
                '_blank',
              )
            }
          >
            <img
              src={K_PIC}
              className="border-0"
              alt="K+"
              style={{ width: '100%', height: 'auto' }}
            ></img>
          </button>
        );
      }
    } else if (this.props?.bank?.bankname === 'ไทยพาณิชย์') {
      if (window.innerWidth > 770) {
        return (
          <button
            className="btn col-12"
            style={{
              background: '#4e2a82',
              borderRadius: '15px',
            }}
            onClick={() =>
              window.open(
                'https://www.scbeasy.com/v1.4/site/presignon/index.asp',
                '_blank',
              )
            }
          >
            <img src={SCB_PIC} className="rounded" alt="SCB"></img>
          </button>
        );
      } else {
        return (
          <button
            className="btn"
            style={{
              background: '#4e2a82',
              borderRadius: '15px',
            }}
            onClick={() =>
              window.open(
                'https://www.scbeasy.com/v1.4/site/presignon/index.asp',
                '_blank',
              )
            }
          >
            <img
              src={SCB_PIC}
              className="rounded"
              alt="SCB"
              style={{ width: '100%', height: 'auto' }}
            ></img>
          </button>
        );
      }
    } else if (this.props?.bank?.bankname === 'กรุงไทย') {
      if (window.innerWidth > 770) {
        return (
          <button
            className="btn col-12"
            style={{
              background: '#00a3e4',
              borderRadius: '15px',
            }}
            onClick={() =>
              window.open('https://www.ktbnetbank.com/consumer/', '_blank')
            }
          >
            <img src={KTB_PIC} className="rounded" alt="KTB"></img>
          </button>
        );
      } else {
        return (
          <button
            className="btn"
            style={{
              background: '#00a3e4',
              borderRadius: '15px',
            }}
            onClick={() =>
              window.open('https://www.ktbnetbank.com/consumer/', '_blank')
            }
          >
            <img
              src={KTB_PIC}
              className="rounded"
              alt="KTB"
              style={{ width: '100%', height: 'auto' }}
            ></img>
          </button>
        );
      }
    }
  }
  inputTime() {
    if (this.props?.bank?.bankname === 'กรุงไทย') {
      return this.state.slHour.getHours() <= 3 ||
        this.state.slHour.getHours() >= 23 ? (
        <div className="mt-3">
          <div>เวลาบนสลิป :</div>
          <div className="container-fluid">
            <div className="row">
              {/* <input
                type="text"
                required="true"
                name={'tempHour'}
                value={this.state.tempHour}
                className="form-control col-5"
                placeholder="ตัวอย่าง 23:50"
                aria-label="Time"
                aria-describedby="basic-addon1"
                onChange={e => this.onChange(e)}
              /> */}
              <select
                className="custom-select w-100 form-control col-5"
                name={'tempHour'}
                value={this.state.tempHour}
                onChange={e => this.onChange(e)}
              >
                <option value="0">00</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>

              <div className="pt-1"> : </div>
              {/* <input
                type="text"
                required="true"
                name={'tempMinute'}
                value={this.state.tempMinute}
                className="form-control col-5"
                placeholder="ตัวอย่าง 23:50"
                aria-label="Time"
                aria-describedby="basic-addon1"
                onChange={e => this.onChange(e)}
              /> */}
              <select
                className="custom-select w-100 form-control col-5"
                name={'tempMinute'}
                value={this.state.tempMinute}
                onChange={e => this.onChange(e)}
              >
                <option value="0">00</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>
                <option value="54">54</option>
                <option value="55">55</option>
                <option value="56">56</option>
                <option value="57">57</option>
                <option value="58">58</option>
                <option value="59">59</option>
              </select>
            </div>
          </div>
        </div>
      ) : (
        <></>
      );
    } else if (
      this.props?.bank?.bankname === 'ไทยพาณิชย์' ||
      this.props?.bank?.bankname === 'กสิกรไทย'
    ) {
      return (
        <div className="mt-3">
          <div>เวลาบนสลิป :</div>
          <div className="container-fluid">
            <div className="row">
              {/* <input
            type="text"
            required="true"
            name={'tempHour'}
            value={this.state.tempHour}
            className="form-control col-5"
            placeholder="ตัวอย่าง 23:50"
            aria-label="Time"
            aria-describedby="basic-addon1"
            onChange={e => this.onChange(e)}
          /> */}
              <select
                className="custom-select w-100 form-control col-5"
                name={'tempHour'}
                value={this.state.tempHour}
                onChange={e => this.onChange(e)}
              >
                <option value="0">00</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>

              <div className="pt-1"> : </div>
              {/* <input
            type="text"
            required="true"
            name={'tempMinute'}
            value={this.state.tempMinute}
            className="form-control col-5"
            placeholder="ตัวอย่าง 23:50"
            aria-label="Time"
            aria-describedby="basic-addon1"
            onChange={e => this.onChange(e)}
          /> */}
              <select
                className="custom-select w-100 form-control col-5"
                name={'tempMinute'}
                value={this.state.tempMinute}
                onChange={e => this.onChange(e)}
              >
                <option value="0">00</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>
                <option value="54">54</option>
                <option value="55">55</option>
                <option value="56">56</option>
                <option value="57">57</option>
                <option value="58">58</option>
                <option value="59">59</option>
              </select>
            </div>
          </div>
        </div>
      );
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(e.target.name, e.target.value)
    if (this.state.chosenPromotion !== 'noPro') {
      this.setState({
        rdPro: 'yes',
      });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    // console.log(this.props)
    let deviceType = device.deviceType;

    if (deviceType === 'browser') {
      deviceType = 'computer';
    }

    // function pad(str, max) {
    //   str = str.toString();
    //   return str.length < max ? pad('0' + str, max) : str;
    // }
    const minute = this.state.slHour.getMinutes();
    const hour = this.state.slHour.getHours();
    const Newminute = minute < 10 ? '0' + minute : '' + minute;
    const Newhour = hour < 10 ? '0' + hour : '' + hour;

    const txtDetail = {
      memberId: this.props?.game?._id,
      systemName: this.props?.game?.gameAccounts[0].systemName,
      clientIp: this.props.ip,
      clientDevice: deviceType,
      txtDate: new Date().toISOString(),
      slHour: Newhour + ':' + Newminute,
      txtMoney: this.state.txtMoney,
      rdBank: this.props?.bank?.bankname,
      txtUser: this.props?.game?.gameAccounts[0].gameUsername,
      txtAccount: this.props?.bankCostomer?.bankAccountNumber.substring(
        this.props?.bankCostomer?.bankAccountNumber.length - 4,
        this.props?.bankCostomer?.bankAccountNumber.length,
      ),
      txtName:
        this.props?.bankCostomer?.nameInBankApp +
        ' : ' +
        this.props?.auth?.jwtExtraction?.username.slice(0, -3) +
        'xxx',
      txtPhone: this.props?.auth?.jwtExtraction?.username,
      chosenPromotion: this.state.chosenPromotion,
      webGroup: this.props?.game?.gameAccounts[0].webGroup,
    };

    if (
      this.state.slHour.getHours().toString() !== this.state.tempHour ||
      this.state.slHour.getMinutes().toString() !== this.state.tempMinute
    ) {
      txtDetail.slHour = this.state.tempHour + ':' + this.state.tempMinute;
    }
    // console.log(txtDetail);
    this.props.gameForDeposit(txtDetail);

    this.setState({
      stepForLoad: this.state.stepForLoad + 1,
    });
  };

  saveImage() {
    var element = document.createElement('a');
    toDataURL(
      `https://ibank.allinonebet.fun/img/${this.props?.bank?.accnumber}.jpg`,
      function(dataUrl) {
        console.log('RESULT:', dataUrl);
        var gh = dataUrl;
        var a = document.createElement('a');
        a.href = gh;
        a.download = 'qrcode.png';

        a.click();
        alert('save qrcode สำเร็จ');
      },
    );
    // var file = new Blob(
    //   [
    //     `https://bank-wallet.oss-ap-southeast-1.aliyuncs.com/bank-qr/sbocash/${this.props?.bank?.accnumber}.jpg`,
    //   ],
    //   { type: 'image/jpg' },
    // );
    // element.href = URL.createObjectURL(file);
    // element.download = 'image.jpg';
    // element.click();
    // alert('save qrcode สำเร็จ');
  }

  render() {
    // console.log(navigator);
    return (
      <div>
        {this.props?.bank?.accnumber === undefined ||
        this.props?.bank?.bankname === undefined ||
        this.props?.bankCostomer?.bankName === undefined ||
        this.props?.bankCostomer?.nameInBankApp === undefined ||
        this.props?.bankCostomer?.bankAccountNumber === undefined ? (
          <div className="container">
            <div className="row">
              <div className="col"></div>
              <div className="col-6 text-center">
                <div className="">
                  <CircularProgress />
                  <br />
                  <span className="mt3-3 text-center">
                    กรุณารอโหลดข้อมูลสักครู่ หากลูกค้ารอนานกรุณากดปุ่ม
                    "รีโหลดหน้าใหม่"
                  </span>
                  <br />
                  <span className="mt3-3 text-center">
                    หากยังไม่ได้ กรุณาติดต่อ
                    <a href="https://lin.ee/v6K6wMn"> "คลิกที่นี่"</a>
                  </span>
                  <br />
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => window.location.reload(false)}
                  >
                    รีโหลดหน้าใหม่
                  </button>
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-3"></div>
              <div className="col-12 col-md-6 mt-3">
                <div className="row">
                  <div className="col-12">
                    <div
                      className="text-center py-3 mt-3"
                      style={{
                        borderRadius: '15px',
                        background: 'red',
                        color: 'white',
                        fontFamily: 'Kanit',
                      }}
                    >
                      <h3>
                        ฝากเงินไม่มีขั้นต่ำ ไม่รับยอดฝากผ่าน <br /> true wallet,
                        ตู้เงินสด, บัญชีผู้อื่น
                      </h3>
                    </div>
                    <div className="mt-3 ">
                      <div className="text-center mt-2">
                        <h4>
                          <pre style={{ color: '#a10a09' }}>
                            ใช้บัญชีด้านล่างสำหรับฝากเงินเท่านั้น
                          </pre>
                        </h4>
                      </div>
                      <table className="table mt-2 text-center border">
                        <thead>
                          <tr>
                            <th scope="col">ธนาคาร</th>
                            <th scope="col">ชื่อบัญชี</th>
                            <th scope="col">เลขบัญชี</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{this.props?.bankCostomer?.bankName}</td>
                            <td>{this.props?.bankCostomer?.nameInBankApp}</td>
                            <td>
                              {this.props?.bankCostomer?.bankAccountNumber}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <img src={Down} alt=""></img>
                    </div>
                    <div className="text-center mt-3">
                      <h2>บัญชีธนาคารสำหรับฝากเงิน</h2>
                    </div>
                    <div className="text-center">
                      <div className="mt-4">{this.bank()}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-12 col-lg-6 text-center">
                        <div>
                          <pre>เลขที่บัญชี</pre>
                        </div>
                        <div>
                          <h1>{this.props?.bank?.accnumber}</h1>
                          {/* <CopyToClipboard
                          text={this.props?.bank?.accnumber}
                          onCopy={() => this.setState({ copied: true })}
                        >
                          <span
                            className="ml-2"
                            type="button"
                            style={{ color: '#3498db', fontSize: 'medium' }}
                          >
                            {' '}
                            [ คัดลอกเลขบัญชี ]{' '}
                          </span>
                        </CopyToClipboard> */}
                        </div>
                      </div>
                      <div className="text-center col-12 col-md-12 col-lg-6">
                        <div>
                          <pre>ชื่อบัญชี</pre>
                        </div>
                        <div>
                          <h1>{this.props?.bank?.accowner}</h1>
                        </div>
                      </div>
                      <div className="col-12 p-2 text-center">
                        <CopyToClipboard
                          text={this.props?.bank?.accnumber}
                          onCopy={() => this.setState({ copied: true })}
                        >
                          <button
                            className="py-2 col-12 btn btn-secondary"
                            type="button"
                            onClick={() =>
                              alert(
                                'คัดลอก เลขบัญชี ' +
                                  this.props?.bank?.accnumber +
                                  ' เรียบร้อยเเล้ว',
                              )
                            }
                            style={{
                              color: '#FDFEFE',
                              fontSize: 'medium',
                              borderRadius: '15px',
                            }}
                          >
                            {' '}
                            [ คัดลอกเลขบัญชี ]{' '}
                          </button>
                        </CopyToClipboard>
                      </div>
                      {this.props?.bank?.bankname === 'กสิกรไทย' ||
                      this.props?.bank?.accnumber === '3572754442' ||
                      this.props?.bank?.accnumber === '9282105060' ||
                      this.props?.bank?.accnumber === '4083105174' ||
                      this.props?.bank?.accnumber === '4150121598' ||
                      this.props?.bank?.accnumber === '9282138924' ? (
                        <div className="col-12 col-md-12 col-lg-12 text-center">
                          <div>
                            <div>
                              <pre>QR Code</pre>
                            </div>
                            <img
                              src={`https://ibank.allinonebet.fun/img/${this.props?.bank?.accnumber}.jpg`}
                              className="rounded"
                              alt="QRCode"
                              style={{ width: '200px', height: 'auto' }}
                            ></img>
                          </div>
                          {device.isIOS ||
                          device.isIOS13 ||
                          device.isIPad13 ||
                          device.isIPhone13 ||
                          device.isIPod13 ? (
                            <div className="text-danger">
                              **หากท่านต้องการบันทึกรูป qr-code
                              สามารถคลิ๊กที่รูปค้างแล้วกดบันทึก
                            </div>
                          ) : (
                            <div className="col-12 mt-2">
                              <button
                                className="btn btn-secondary col-12 text-center "
                                download
                                onClick={() => this.saveImage()}
                                style={{
                                  color: '#FDFEFE',
                                  fontSize: 'medium',
                                  borderRadius: '15px',
                                }}
                              >
                                [ บันทึกรูป คิวอาร์โค้ด ]
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-info col-12 py-2"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        style={{ borderRadius: '15px' }}
                      >
                        <h4>แจ้งรายการฝาก</h4>
                      </button>
                      {this.warningTime()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3"></div>
            </div>
          </div>
        )}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <form onSubmit={e => this.onSubmit(e)}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    เเจ้งรายการฝาก
                  </h5>
                </div>
                {this.state.stepForLoad !== 1 ? (
                  <div>
                    <div className="modal-body">
                      <div className="mt-3">จำนวนเงิน : </div>
                      <input
                        type="number"
                        required={true}
                        name="txtMoney"
                        value={this.state.txtMoney}
                        className="form-control"
                        placeholder="จำนวนเงิน"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={e => this.onChange(e)}
                      />
                      <div className="container mt-3">
                        <div className="row d-flex justify-content-center">
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="100"
                            onClick={e => this.onChange(e)}
                          >
                            100{' '}
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="150"
                            onClick={e => this.onChange(e)}
                          >
                            150{' '}
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="200"
                            onClick={e => this.onChange(e)}
                          >
                            200{' '}
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="300"
                            onClick={e => this.onChange(e)}
                          >
                            300{' '}
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="400"
                            onClick={e => this.onChange(e)}
                          >
                            400{' '}
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="500"
                            onClick={e => this.onChange(e)}
                          >
                            500{' '}
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="1000"
                            onClick={e => this.onChange(e)}
                          >
                            1000
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="2000"
                            onClick={e => this.onChange(e)}
                          >
                            2000
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="3000"
                            onClick={e => this.onChange(e)}
                          >
                            3000
                          </button>
                          <button
                            type="button"
                            className="m-1 buttonGod"
                            name="txtMoney"
                            value="5000"
                            onClick={e => this.onChange(e)}
                          >
                            5000
                          </button>
                        </div>
                      </div>
                      <div className="mt-3">รับโบนัส : </div>
                      <select
                        className="custom-select w-100 form-control"
                        name={'chosenPromotion'}
                        value={this.state.chosenPromotion}
                        onChange={e => this.onChange(e)}
                      >
                        <option value="noPro">ไม่รับ</option>
                        <option value="plus10p">รับ</option>
                      </select>
                      {this.inputTime()}
                      {this.state.chosenPromotion === 'noPro' ? (
                        <></>
                      ) : (
                        <div className="container-fluid">
                          <div className="row justify-content-center">
                            <div className="mt-1 col-11">
                              เพิ่ม 10% สูงสุด 500 บาท ต้องมียอดการเล่น 10 เท่า
                              (ยอดฝาก + โบนัสx10) <br />
                              ** เครดิตก่อนเติม ของท่านต้องมีน้อยกว่า 200 บาท
                              เท่านั้นถึงรับโบนัสได้ {'**'}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        ยกเลิก
                      </button>
                      <button type="button submit" className="btn btn-primary">
                        เเจ้งฝาก
                      </button>
                    </div>
                  </div>
                ) : this.props.tranGame ? (
                  <div>
                    <div className="modal-body">
                      <div className="text-center">
                        <h6>
                          ท่านได้ทำรายการเรียบร้อยแล้ว กรุณารอ ภายใน{' '}
                          <ins>3-15 วินาที</ins> หรือหากเกิน 5 นาที กรุณาติดต่อ
                          Callcenter
                        </h6>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary col-12"
                        onClick={() => window.location.reload(false)}
                      >
                        เสร็จสิ้น
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="text-center">
                      <h6>รอสักครู่ระบบกำลังทำรายการ</h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  ip: state.auth.ip,
  game: state.game.dataGame?.datas,
  tranGame: state.game?.tranGame,
  bank:
    state.bank.dataBank?.datas?.data?.bankAccount[
      state.bank.dataBank?.datas?.data?.bankAccount.length - 1
    ],
  bankCostomer: state.bank?.dataBankWithdraw?.datas?.bankAccounts[0],
});

export default connect(mapStateToProps, {
  getBank,
  getGame,
  getBankAccount,
  gameForDeposit,
})(Deposit);
