import React, { Component } from 'react';

class Door extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                ทางเข้า COMPUTER
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(' https://www.sbobet888.com/', '_blank')
                    }
                  >
                    ทางเข้า 1
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(' https://www.sbo.bz/sbobet/', '_blank')
                    }
                  >
                    ทางเข้า 2
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://www.pic5678.com/?sitepref=www',
                        '_blank',
                      )
                    }
                  >
                    ทางเข้า 3
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://www.thantai68.com/th-th/betting.aspx?Sitepref=www',
                        '_blank',
                      )
                    }
                  >
                    ทางเข้า 4
                  </button>
                </li>
                {/* <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://www.ujihoki.com/th-th/betting.aspx?Sitepref=www',
                        '_blank',
                      )
                    }
                  >
                    ujihoki
                  </button>
                </li> */}
                {/* <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://www.beer789.com/th-th/betting.aspx?Sitepref=www',
                        '_blank',
                      )
                    }
                  >
                    beer789
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://www.currybread.com/th-th/betting.aspx',
                        '_blank',
                      )
                    }
                  >
                    currybread
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://img.eaxybox.com/th-th/betting.aspx',
                        '_blank',
                      )
                    }
                  >
                    eaxybox
                  </button>
                </li> */}
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Door;
