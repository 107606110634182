// Auth
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

// Alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Credit
export const GET_CREDIT_SUCCESS = 'GET_CREDIT_SUCCESS';
export const GET_CREDIT_FAIL = 'GET_CREDIT_FAIL';
export const MOVE_CREDIT_SUCCESS = 'MOVE_CREDIT_SUCCESS';
export const MOVE_CREDIT_FAIL = 'MOVE_CREDIT_FAIL';
export const GET_CREDIT_HISTORY = 'GET_CREDIT_HISTORY';
export const GET_CREDIT_HISTORY_ONE_CREDIT = 'GET_CREDIT_HISTORY_ONE_CREDIT';

// Game
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';
export const POST_GAME_DEPOSIT_SUCCESS = 'POST_GAME_DEPOSIT_SUCCESS';
export const POST_GAME_WITHDRAW_SUCCESS = 'POST_GAME_WITHDRAW_SUCCESS';
export const GET_CHECK_LATEST_TASK_DEPOSIT_SUCCESS =
  'GET_CHECK_LATEST_TASK_DEPOSIT_SUCCESS';
export const GET_CHECK_LATEST_TASK_DEPOSIT_FAIL =
  'GET_CHECK_LATEST_TASK_DEPOSIT_FAIL';
export const GET_CHECK_LATEST_TASK_DEPOSIT_WAITING =
  'GET_CHECK_LATEST_TASK_DEPOSIT_WAITING';

//Bank
export const GET_BANK_SUCCESS = 'GET_BANK_SUCCESS';
export const GET_BANK_FAIL = 'GET_BANK_FAIL';
export const GET_BANK_WITHDRAW_SUCCESS = 'GET_BANK_WITHDRAW_SUCCESS';
export const GET_BANK_WITHDRAW_FAIL = 'GET_BANK_WITHDRAW_FAIL';

//Setting
export const CHANGEPASSWORD_SUCCESS = 'CHANGEPASSWORD_SUCCESS';
export const CHANGEPASSWORD_FAIL = 'CHANGEPASSWORD_FAIL';

//OTP
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAIL = 'OTP_FAIL'
export const OTP_VALIDATION_SUCCESS = 'OTP_VALIDATION_SUCCESS';
export const OTP_VALIDATION_FAIL = 'OTP_VALIDATION_FAIL';

//GetIP
export const GET_IP_SUCCESS = 'GET_IP_SUCCESS';
export const GET_IP_FAIL = 'GET_IP_FAIL';
