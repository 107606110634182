import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// import Mainwallet from '../wallet/MainWallet';
import Information from './costomer/Information';
// import Mainsetting from '../setting/Mainsetting';
import Tranfer from './tranfer/Tranfer';
import Door from './door/Door';
import DoorMobile from './door/DoorMobile';
import Doomovie from './doomovie/Doomovie';
import Otherweb from './otherweb/Otherweb';
import Spoil from './spoil/Spoil';
import popup from '../../LOGO/pro1212.jpg';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstModal: true,
    };
  }
  render() {
    return (
      <div>
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-12 border">test</div>
          </div> */}
          <div className="row">
            <div className="col-12 col-md-1"></div>
            <div className="col-12 col-md-3 mt-3">
              <Tranfer />
              <div className="mt-3">
                <Information />
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <Door />
              <DoorMobile />
            </div>
            <div className="col-12 col-md-3 mt-3">
              {/* <Mainsetting /> */}
              <Doomovie />
              <Spoil />
              <Otherweb />
            </div>
            <div className="col-12 col-md-1"></div>
          </div>
        </div>
        <div
          class={
            'modal fade' + (this.state.firstModal ? ' show d-block' : ' d-none')
          }
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header" style={{ padding : '0px' }}> 
                <button
                  type="button"
                  class="btn btn-danger btn-block"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({ firstModal: !this.state.firstModal })
                  }
                >
                  <span aria-hidden="true" className="h5 text-white">X</span>
                </button>                
              </div>
              <div class="modal-body" style={{ padding : '0px' }}>
                <div className="text-center">
                  <button
                    className="btn col-12"
                    onClick={() => window.open('https://www.sbo.bz', '_blank')}
                  >
                    <img
                      src="https://web-image-oss.oss-ap-southeast-3.aliyuncs.com/popup.jpg"
                      style={{ width: '100%', height: '100%' }}
                      className="rounded"
                      alt="huay"
                    ></img>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
