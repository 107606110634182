import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  registerOTP,
  validateOTP,
  clearValidateOTP,
} from '../../Actions/OTPAcion';
import { registration, login } from '../../Actions/AuthAction';
import PropTypes from 'prop-types';

// material-ui
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import TextsmsIcon from '@material-ui/icons/Textsms';
import CreateIcon from '@material-ui/icons/Create';
import StepConnector from '@material-ui/core/StepConnector';
// import Button from "@material-ui/core/Button";
// import Typography from '@material-ui/core/Typography';
// import FileCopyIcon from '@material-ui/icons/FileCopy';

import moment from 'moment';

// image
import กสิกรไทย from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (2).png';
import ไทยพาณิชย์ from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (8).png';
import กรุงไทย from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (4).png';
import กรุงเทพ from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (1).png';
import ทหารไทย from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (7).png';
import กรุงศรี from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (14).png';
import ออมสิน from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (18).png';
import ธกส from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (22).png';
import ธนชาต from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (25).png';
import UOB from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (13).png';
import LH from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (31).png';
import เกียรตินาคิน from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (28).png';
import อาคารสงเคราะห์ from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (21).png';
import CIMB from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (12).png';
import ICBC from '../../LOGO/bankIcon/ThaiBank/52px/Thai Bank (29).png';

import $ from 'jquery';
import './Register.css';

// const QontoConnector = withStyles({
//   alternativeLabel: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   active: {
//     '& $line': {
//       borderColor: '#784af4',
//     },
//   },
//   completed: {
//     '& $line': {
//       borderColor: '#784af4',
//     },
//   },
//   line: {
//     borderColor: '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// })(StepConnector);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(120deg, #3498db, #8e44ad)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient(120deg, #3498db, #8e44ad)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient(120deg, #3498db, #8e44ad)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient(120deg, #3498db, #8e44ad)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SmartphoneIcon />,
    2: <TextsmsIcon />,
    3: <CreateIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      steps: ['กรอกเบอร์มือถือ', 'กรอกรหัส OTP', 'กรอกข้อมูล'],
      number: '',
      otp: '',
      errorCode: '',
      password: '',
      passwordConfirm: '',
      bankNumber: '',
      firstName: '',
      lastName: '',
      hdbCustomer: '',
      lineCustomer: '',
      bankCustomer: 'กสิกรไทย',
      errorPassword: '',
      errorHBD: '',
      showStatus: 'hide',
      otpError: '',
      iconBanks: [
        {
          value: กสิกรไทย,
          name: 'กสิกรไทย',
        },
        {
          value: ไทยพาณิชย์,
          name: 'ไทยพาณิชย์',
        },
        {
          value: กรุงไทย,
          name: 'กรุงไทย',
        },
        {
          value: กรุงเทพ,
          name: 'กรุงเทพ',
        },
        {
          value: ทหารไทย,
          name: 'ทหารไทย',
        },
        {
          value: กรุงศรี,
          name: 'กรุงศรี',
        },
        {
          value: ออมสิน,
          name: 'ออมสิน',
        },
        {
          value: ธกส,
          name: 'ธกส',
        },
        {
          value: ธนชาต,
          name: 'ธนชาต',
        },
        {
          value: UOB,
          name: 'UOB',
        },
        {
          value: LH,
          name: 'แลนด์แอนเฮ้าส์',
        },
        {
          value: เกียรตินาคิน,
          name: 'เกียรตินาคิน',
        },
        {
          value: อาคารสงเคราะห์,
          name: 'อาคารสงเคราะห์',
        },
        {
          value: CIMB,
          name: 'CIMB',
        },
        {
          value: ICBC,
          name: 'ICBC',
        },
      ],
    };
  }

  componentDidMount() {
    $('#exampleModal').modal(`${this.state.showStatus}`);
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeBank = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    document.getElementById(e.target.value).checked = true;
  };

  onClick = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getStepContent(step) {
    const activeStep = this.state.activeStep - 1;
    switch (step) {
      case 0:
        return this.step1();
      case 1:
        return !this.props.otp.dataRegister
          ? this.stepWait()
          : this.props.otp.dataRegister?.code === '999999'
          ? this.step2()
          : this.setState({
              activeStep,
              otpError: `ไม่สามารถใช้เบอร์นี้ได้ กรุณาติดต่อ Call center`,
            });
      case 2:
        return this.props.otp.dataValidate
          ? this.stepWait()
          : this.props.otp?.dataValidate?.code !== '999999'
          ? this.step3()
          : this.setState({
              activeStep,
              errorCode: this.props.otp?.dataValidate?.message,
            });
      case 3:
        return this.props.registerStatus
          ? this.stepFinish()
          : this.stepFinish();
      default:
        return 'ไม่พบรายการ';
    }
  }

  step1() {
    return (
      <div>
        <div className="mt-3">
          <div>เบอร์โทรศัพท์ : </div>
          <input
            required={true}
            type="number"
            className="col-12 form-control mt-2"
            name={'number'}
            value={this.state.number}
            placeholder="ตัวอย่าง 0812345678"
            onChange={e => this.onChange(e)}
          ></input>
          {this.state.otpError ? (
            <div className="text-danger text-center">
              {this.state.otpError}{' '}
              <a href="https://lin.ee/v6K6wMn">
                https://lin.ee/v6K6wMn (คลิ๊ก)
              </a>{' '}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  step2() {
    return (
      <div>
        <div className="mt-3">
          <div>OTP : </div>
          <input
            required="true"
            type="number"
            className="col-12 form-control mt-2"
            name={'otp'}
            value={this.state.otp}
            placeholder="กรอก OTP ที่ท่านได้รับ"
            onChange={e => this.onChange(e)}
          ></input>
          <div className="col-12 text-center text-button mt-1">
            <button
              type="button"
              className="btn"
              onClick={() => this.OtpAgain()}
            >
              ขอ OTP อีกครั้ง
            </button>
          </div>
          <div className="text-danger mt-2">{this.state.errorCode}</div>
        </div>
      </div>
    );
  }

  step3() {
    return (
      <div className="container">
        <div className="mt-3 row">
          <div className="col-12 col-md-6 mb-4">
            <div className="col-12 text-center">
              <h4>รหัสผ่าน</h4>
            </div>
            <div className="mt-4">
              <input
                required="true"
                type="password"
                className="col-12 form-control mt-2"
                name={'password'}
                value={this.state.password}
                placeholder="รหัสผ่านอย่างน้อย 8 ตัวอักษร"
                onChange={e => this.onChange(e)}
              ></input>
              <input
                required="true"
                title="5 to 10 characters"
                type="password"
                className="col-12 form-control mt-2"
                name={'passwordConfirm'}
                value={this.state.passwordConfirm}
                placeholder="ยืนยันรหัสผ่าน"
                onChange={e => this.onChange(e)}
              ></input>
              <div className="text-danger">{this.state.errorPassword}</div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <div className="col-12 text-center">
              <h4>ข้อมูลส่วนตัว</h4>
            </div>
            <div className="mt-4">
              <input
                required="true"
                type="text"
                className="col-12 form-control mt-2"
                name={'firstName'}
                value={this.state.firstName}
                placeholder="ชื่อ (ภาษาไทย)"
                onChange={e => this.onChange(e)}
              ></input>
              <input
                required="true"
                type="text"
                className="col-12 form-control mt-2"
                name={'lastName'}
                value={this.state.lastName}
                placeholder="นามสกุล (ภาษาไทย)"
                onChange={e => this.onChange(e)}
              ></input>
              {/* <div className="p-0 mt-2">วัน/เดือน/ปีเกิด (ค.ศ.)</div>
              <input
                required="true"
                type="date"
                className="col-12 form-control"
                name={'hdbCustomer'}
                value={this.state.hdbCustomer}
                onChange={e => this.onChange(e)}
              ></input> */}
              <div className="text-danger">{this.state.errorHBD}</div>
              <div className="p-0 mt-2">
                ID Line (สามารถใส่เป็นเบอร์โทรศัพท์ได้)
              </div>
              <input
                required="true"
                type="text"
                className="col-12 form-control"
                name={'lineCustomer'}
                value={this.state.lineCustomer}
                placeholder="ไอดีไลน์"
                onChange={e => this.onChange(e)}
              ></input>
            </div>
          </div>
          <div className="col-12">
            <div className="col-12 text-center">
              <h4>เลือกธนาคาร</h4>
            </div>
            <div className="col-12">
              <div className="container">
                <div className="row justify-content-center col-12">
                  {this.iconBank()}
                </div>
              </div>
            </div>
            <div className="mt-2">เลขที่บัญชีธนาคาร</div>
            <input
              required="true"
              type="number"
              pattern=".{10,12}"
              title="10 ถึง 12 ตัวอักษร"
              className="col-12 form-control mt-2"
              name={'bankNumber'}
              value={this.state.bankNumber}
              placeholder="เช่น 1234567888"
              onChange={e => this.onChange(e)}
            ></input>
            {this.inputBank()}
          </div>
        </div>
      </div>
    );
  }

  OtpAgain() {
    this.props.registerOTP(this.state.number);
    alert('ท่านได้ขอ OTP ใหม่เรียบร้อยเเล้ว');
  }

  stepWait() {
    return (
      <div className="container">
        <div className="mt-3 row">
          <div className="col-5 ">กรุณารอสักครู่</div>
        </div>
      </div>
    );
  }

  stepFinish() {
    return (
      <div className="container">
        <div className="mt-3 row">
          <div className="col-12 text-center">
            ท่านทำรายการสมัครสำเร็จกรุณารอ SMS ยืนยันการทำรายการ
          </div>
          <div className="col-12 mt-3">
            <div className="row  justify-content-center">
              <div className="col-3 text-center ">Username : </div>
              <div className="col-5">{this.state.number}</div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row  justify-content-center">
              <div className="col-3 text-center ">Password : </div>
              <div className="col-5">{this.state.password}</div>
            </div>
          </div>
          <div className="col-12 text-center text-danger mt-4">
            ** Username เเละ Password นี้ใช้ในการเข้าใช้งานเว็บ Member เท่านั่น
            **
          </div>
        </div>
      </div>
    );
  }

  inputBank() {
    return (
      <select
        className="custom-select w-100 form-control col-12 mt-2"
        name={'bankCustomer'}
        value={this.state.bankCustomer}
        onChange={e => this.onChangeBank(e)}
      >
        {this.state.iconBanks.map(iconBank => {
          return <option value={iconBank.name}>{iconBank.name}</option>;
        })}
      </select>
    );
  }
  iconBank() {
    return this.state.iconBanks.map((iconBank, i) => {
      return (
        <div className="d-flex p-1 variante-radio ">
          <input
            type="radio"
            name="bankCustomer"
            value={iconBank.name}
            id={iconBank.name}
            onClick={e => this.onClick(e)}
          />
          <label
            for={iconBank.name}
            title={iconBank.name}
            style={{ backgroundImage: `url(${iconBank.value})` }}
          >
            <i className="fa fa-check" aria-hidden="true"></i>
          </label>
        </div>
      );
    });
  }

  onSubmitRegister = async e => {
    e.preventDefault();
    const activeStep = this.state.activeStep + 2;
    if (this.state.activeStep === 0) {
      this.props.registerOTP(this.state.number);
      this.setState({
        activeStep,
      });
    } else if (this.state.activeStep === 1) {
      await this.props.clearValidateOTP();
      await this.props.validateOTP(this.state.number, this.state.otp);
      this.setState({
        activeStep,
      });
    } else if (this.state.activeStep === 2) {
      if (this.state.password === this.state.passwordConfirm) {
        if (this.state.password.length >= 8) {
          let now = new Date();
          // let a = moment(now);
          // let b = moment(this.state.hdbCustomer);
          // let diffDuration = moment.duration(a.diff(b));
          if (now) {
            const tempDetail = {
              telNumbers: this.state.number,
              bankName: this.state.bankCustomer,
              bankAccountNumber: this.state.bankNumber,
              lineId: this.state.lineCustomer,
              name: this.state.firstName + ' ' + this.state.lastName,
              systemName: 'sbo',
              birthday: moment(now),
              requestedPassword: this.state.password,
              ip: this.props.ip,
              createDate:
                moment(now).format('YYYY') +
                '-' +
                moment(now).format('MM') +
                '-' +
                moment(now).format('DD') +
                ' ' +
                moment(now).format('HH:mm:ss'),
            };
            this.setState({
              errorPassword: '',
              activeStep,
            });
            this.props.registration(tempDetail);
          } else {
            this.setState({
              errorHBD: 'อายุท่านไม่ถึง 18 ปี',
            });
          }
        } else {
          this.setState({
            errorPassword: 'Password อย่างน้อย 8 ตัวอักษร',
          });
        }
      } else {
        this.setState({
          errorPassword: 'Password ไม่ตรงกัน',
        });
      }
    } else if (this.state.activeStep === 3) {
      console.log('number : ', this.state.number);
      console.log('password : ', this.state.password);
      await this.props.login(this.state.number, this.state.password);
      // await window.location.reload(false);
      setTimeout(() => {
        window.location.reload(false);
      }, 4000);
    }
  };

  render() {
    return (
      <div>
        <button
          type="button"
          className="btn rounded-pill w-100 color-button text-white"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          สมัครสมาชิก
        </button>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          aria-modal="true"
        >
          <form onSubmit={e => this.onSubmitRegister(e)}>
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    สมัครสมาชิก
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Stepper
                    alternativeLabel
                    activeStep={this.state.activeStep}
                    connector={<ColorlibConnector />}
                  >
                    {this.state.steps.map(label => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {this.getStepContent(this.state.activeStep)}
                </div>
                <div className="modal-footer">
                  <button
                    type="button submit"
                    className="btn btn-primary col-12"
                    // onClick={e => this.onSubmit(e)}
                  >
                    ยืนยัน
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    otp: state.otp,
    registerStatus: state.auth.registerStatus,
    ip: state.auth.ip,
  };
}

export default connect(mapStateToProps, {
  registerOTP,
  validateOTP,
  clearValidateOTP,
  registration,
  login,
})(Register);
