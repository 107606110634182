import {
  GET_CREDIT_SUCCESS,
  GET_CREDIT_FAIL,
  MOVE_CREDIT_SUCCESS,
  MOVE_CREDIT_FAIL,
  GET_CREDIT_HISTORY,
  GET_CREDIT_HISTORY_ONE_CREDIT,
} from "../Actions/types";

const initialState = {
  dataCredit: null,
  dataHistoryCredit: null,
  dataMoveCredit: null,
  dataOneCredit: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CREDIT_SUCCESS:
      return { ...state, dataCredit: payload };
    case GET_CREDIT_FAIL:
      return { ...state, dataCredit: payload };
    case MOVE_CREDIT_SUCCESS:
      return { ...state, dataMoveCredit: payload };
    case MOVE_CREDIT_FAIL:
      return { ...state, dataMoveCredit: payload };
    case GET_CREDIT_HISTORY:
      return { ...state, dataHistoryCredit: payload };
    case GET_CREDIT_HISTORY_ONE_CREDIT:
      return { ...state, dataOneCredit: payload };
    default:
      return state;
  }
}
