import React, { Component } from 'react';

class Doomovie extends Component {
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                ดูหนังออนไลน์ {'&'} ดูบอลสด
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-dark col-12"
                    onClick={() =>
                      window.open('https://doomovie.com/', '_blank')
                    }
                  >
                    ดูหนัง (คลิ๊ก)
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-dark col-12"
                    onClick={() =>
                      window.open(
                        'https://doomovie.com/%E0%B8%97%E0%B8%B5%E0%B8%A7%E0%B8%B5%E0%B8%AA%E0%B8%94',
                        '_blank',
                      )
                    }
                  >
                    ดูบอลสด (คลิ๊ก)
                  </button>
                </li>
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Doomovie;
