import { combineReducers } from 'redux';
import alert from './AlertReducer';
import auth from './AuthReducer';
import credit from './CreditReducer';
import game from './GameReducer';
import bank from './BankReducers';
import otp from './OTPReducer';

const rootReducer = combineReducers({ alert, auth, credit, game, bank, otp });

export default rootReducer;
