import React from 'react';
import './sideDrawer.css';
import { NavLink } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import TvIcon from '@material-ui/icons/Tv';
import CheckIcon from '@material-ui/icons/Check';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import quit from '../../../LOGO/quit.png';
import { connect } from 'react-redux';
import { logout } from '../../../Actions/AuthAction';

const sideDrawer = props => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  // console.log(this.props)
  return (
    <nav className={drawerClasses}>
      <div className="d-flex bd-highlight">
        <div className="mt-2 text-white p-2 w-100 bd-highlight">
          <h5>
            สวัสดีคุณ :{' '}
            {props.auth.user && props.auth.user.jwtExtraction.name_TH}
          </h5>
        </div>
        <div className="p-2 flex-shrink-1 bd-highlight mt-2">
          <img src={quit} onClick={props.click} alt="quitButton"></img>
        </div>
      </div>
      {/* <div className="ml-5 text-white">
                <h6>
                    ยอดเงินคงเหลือ :{" "}

                </h6>
            </div> */}
      <hr className="border" />
      <ul className="text-white">
        <li className="nav-item">
          <NavLink to="/dashboard" onClick={props.click}>
            <HomeIcon className="mr-2" />
            หน้าแรก
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/deposit" onClick={props.click}>
            <PaymentIcon className="mr-2" />
            เเจ้งฝาก
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/withdraw" onClick={props.click}>
            <AccountBalanceWalletIcon className="mr-2" />
            เเจ้งถอน
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/setting" onClick={props.click}>
            <SettingsIcon className="mr-2" />
            เปลี่ยนพาสเวิร์ด
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/#" onClick={props.logout}>
            <ExitToAppIcon className="mr-2" />
            Logout
          </NavLink>
        </li>
      </ul>

      <hr className="border" />
      <ul className="text-white">
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() => window.open('https://doomovie.com/', '_blank')}
          >
            <TvIcon className="mr-2" />
            ดูหนังออนไลน์
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() =>
              window.open(
                'https://doomovie.com/%E0%B8%97%E0%B8%B5%E0%B8%A7%E0%B8%B5%E0%B8%AA%E0%B8%94',
                '_blank',
              )
            }
          >
            <SportsSoccerIcon className="mr-2" />
            ดูบอลสด
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() => window.open('https://www.sbo.bz/tded/', '_blank')}
          >
            <ImportContactsIcon className="mr-2" />
            ทีเด็ด sbo.bz
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() =>
              window.open('https://www.sbobet888.com/SBOBET-Tded/', '_blank')
            }
          >
            <ImportContactsIcon className="mr-2" />
            ทีเด็ด sbobet888
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() =>
              window.open(
                'https://www.gclub88888vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3.php',
                '_blank',
              )
            }
          >
            <CheckIcon className="mr-2" />
            Gclub
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() =>
              window.open(
                'https://www.918kisskiss918vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81.php',
                '_blank',
              )
            }
          >
            <CheckIcon className="mr-2" />
            918Kiss
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() =>
              window.open(
                'https://www.starvegas88888vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81.php',
                '_blank',
              )
            }
          >
            <CheckIcon className="mr-2" />
            Starvegas
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/#"
            onClick={() =>
              window.open(
                'https://www.ufabet88888vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81.php',
                '_blank',
              )
            }
          >
            <CheckIcon className="mr-2" />
            Ufabet
          </NavLink>
        </li>
      </ul>

      <div className="text-white d-flex justify-content-center border-top ">
        <span className="side-drawer-update "> Copyright @ SBO_BZ</span>
      </div>
    </nav>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(sideDrawer);
