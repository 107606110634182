import axios from 'axios';
import {
  GET_GAMES_SUCCESS,
  GET_GAMES_FAIL,
  POST_GAME_DEPOSIT_SUCCESS,
  POST_GAME_WITHDRAW_SUCCESS,
  GET_CHECK_LATEST_TASK_DEPOSIT_SUCCESS,
  GET_CHECK_LATEST_TASK_DEPOSIT_WAITING,
  GET_CHECK_LATEST_TASK_DEPOSIT_FAIL,
} from './types';
import { setAlert } from './AlertAction';

export const getGame = () => async dispatch => {
  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }game/gameAccount`, config);
    dispatch({
      type: GET_GAMES_SUCCESS,
      payload: res.data,
    });
    // window.location.reload(false);
  } catch (err) {
    dispatch({
      type: GET_GAMES_FAIL,
    });
  }
};

export const gameForDeposit = txtDetail => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.token,
    },
  };

  // var ClientIp = '';
  // try {
  //   const res = await axios.get(
  //     'https://cors-anywhere.herokuapp.com/https:.ipify.org?format=json',
  //   );
  //   ClientIp = res.data;
  // } catch (error) {
  //   console.log(error);
  // }

  // console.log(txtDetail.chosenPromotion);
  if (txtDetail.chosenPromotion === 'noPro') {
    txtDetail.rdPro = 'no';
  } else {
    txtDetail.rdPro = 'yes';
  }
  // txtDetail.clientIp = ClientIp.ip;
  txtDetail.type = 'deposit'
  txtDetail.site = window.location.hostname;
  const body = JSON.stringify({ txtDetail });
  // console.log(body);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }game/movedeposit`, body, config);

    // console.log('POST_GAME_DEPOSIT_SUCCESS', body);

    if (res.data.code === '999999') {
      dispatch({
        type: POST_GAME_DEPOSIT_SUCCESS,
        payload: res.data,
      });
      await dispatch(setAlert('ท่านทำรายการเเจ้งฝากสำเร็จเเล้ว', 'success'));
    }
  } catch (err) {
    // console.log(err);
  }
};

export const gameForWithdraw = txtDetail => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.token,
    },
  };

  // let ClientIp2 = '';
  // try {
  //   const res = await axios.get(
  //     'https://cors-anywhere.herokuapp.com/https:/.ipify.org?format=json',
  //   );
  //   ClientIp2 = res.data;
  // } catch (error) {
  //   console.log(error);
  // }
  // txtDetail.clientIp = ClientIp2.ip;
  txtDetail.site = window.location.hostname;
  txtDetail.type = 'withdraw'
  const body = JSON.stringify({ txtDetail });
  // console.log(body);
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }game/movewithdraw`, body, config);

    // console.log(res);

    if (res.data.code === '999999') {
      dispatch({
        type: POST_GAME_WITHDRAW_SUCCESS,
        payload: res.data,
      });
      await dispatch(setAlert("ท่านทำรายการเเจ้งถอนสำเร็จเเล้ว'", 'success'));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    // console.log(err);
  }
};

export const getLatestTaskByMember = (
  gameUsername,
  deposit,
) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const txtCheck = {
    member: gameUsername,
    deposit: deposit,
  };

  const body = JSON.stringify({ txtCheck });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }game/getlatesttaskbymember`,
      body,
      config,
    );

    if (res.data.code === '999999' && res.data.message === 'done!') {
      if (res.data.data.deposit?.status === 'Waiting') {
        dispatch({
          type: GET_CHECK_LATEST_TASK_DEPOSIT_WAITING,
          payload: res.data.data.deposit,
        });
      } else if (res.data.data.deposit?.status === 'Success') {
        dispatch({
          type: GET_CHECK_LATEST_TASK_DEPOSIT_SUCCESS,
          payload: res.data.data.deposit,
        });
      }
    } else {
      dispatch({
        type: GET_CHECK_LATEST_TASK_DEPOSIT_FAIL,
      });
    }
    // console.log(res.data);
  } catch (error) {
    // console.log(error);
  }
};
