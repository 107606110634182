import React, { Component } from 'react';

class Otherweb extends Component {
  render() {
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                รวม 4 คาสิโนดัง
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn col-12"
                    style={{ background: '#b3ffc6' }}
                    onClick={() =>
                      window.open(
                        'https://www.gclub88888vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3.php',
                        '_blank',
                      )
                    }
                  >
                    สนใจสมัคร gclub
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn col-12"
                    style={{ background: '#b3ffc6' }}
                    onClick={() =>
                      window.open(
                        'https://www.918kisskiss918vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81.php',
                        '_blank',
                      )
                    }
                  >
                    สนใจสมัคร 918kiss
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn col-12"
                    style={{ background: '#b3ffc6' }}
                    onClick={() =>
                      window.open(
                        'https://www.starvegas88888vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81.php',
                        '_blank',
                      )
                    }
                  >
                    สนใจสมัคร starvegas
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn col-12"
                    style={{ background: '#b3ffc6' }}
                    onClick={() =>
                      window.open(
                        'https://www.ufabet88888vip.com/%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81.php',
                        '_blank',
                      )
                    }
                  >
                    สนใจสมัคร ufabet
                  </button>
                </li>
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Otherweb;
