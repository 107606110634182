import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { getGame } from '../../../Actions/GameAction';
import { getBankAccount } from '../../../Actions/BankAction';

class Information extends Component {
  componentDidMount() {
    this.props.getBankAccount();
  }
  render() {
    // console.log(this.props.bank);
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                Costomer Information
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">ชื่อ (ไทย)</div>
                    <div>{this.props?.auth?.jwtExtraction.name_TH}</div>
                  </div>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">ชื่อ (อังกฤษ)</div>
                    <div>{this.props?.auth?.jwtExtraction.name_EN}</div>
                  </div>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">Username</div>
                    <div>{this.props?.auth?.jwtExtraction.username}</div>
                  </div>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">ธนาคาร</div>
                    <div>{this.props?.bank?.bankName}</div>
                  </div>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <div className="row">
                    <div className="col-5">เลขบัญชีธนาคาร</div>
                    <div>{this.props?.bank?.bankAccountNumber}</div>
                  </div>
                </li>
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth.user,
  bank: state.bank?.dataBankWithdraw?.datas?.bankAccounts[0],
});

export default connect(mapStateToProps, { getBankAccount })(Information);
