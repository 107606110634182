import axios from 'axios';
import {
  GET_CREDIT_SUCCESS,
  GET_CREDIT_FAIL,
  MOVE_CREDIT_SUCCESS,
  MOVE_CREDIT_FAIL,
  GET_CREDIT_HISTORY,
  GET_CREDIT_HISTORY_ONE_CREDIT,
} from './types';

import { setAlert } from './AlertAction';

export const getCredit = () => async dispatch => {
  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }credit/creditRemaining`, config);
    dispatch({
      type: GET_CREDIT_SUCCESS,
      payload: res.data,
    });
    // window.location.reload(false);
  } catch (err) {
    dispatch({
      type: GET_CREDIT_FAIL,
    });
  }
};

export const moveCredit = (
  source,
  destination,
  amount,
  timestamp,
) => async dispatch => {
  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };

  const body = {
    source: source,
    destination: destination,
    amount: amount,
    timeStamp_command: timestamp,
  };

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }credit/creditTransfer`, body, config);
    // console.log(res.data);
    dispatch({
      type: MOVE_CREDIT_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, 'success'));
    // window.location.reload(false);
  } catch (err) {
    dispatch({
      type: MOVE_CREDIT_FAIL,
    });
  }
};

export const getHistory = () => async dispatch => {
  // console.log('client start', Date().toLocaleString());
  await axios
    .get(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }credit/history`, {
      headers: { Authorization: localStorage.token },
    })
    .then(res => {
      // console.log(res)
      if (res.data.code === '999999') {
        dispatch({
          type: GET_CREDIT_HISTORY,
          payload: res.data,
        });
        // console.log('client finish', Date().toLocaleString());
      } else {
        dispatch(setAlert(res.data.message, 'danger'));
      }
    });

  // const config = {
  //   headers: {
  //     authorization: localStorage.token,
  //   },
  // };
  // console.log("client1", Date().toLocaleString());
  // try {
  //   const res = await axios.get(
  //     `https://e1.euro555.com/memberData/creditTransfer/history`,
  //     config
  //   );
  //   console.log(res.data);
  //   if (res.data.code === "999999") {
  //     dispatch({
  //       type: GET_CREDIT_HISTORY,
  //       payload: res.data,
  //     });
  //     console.log("client2", Date().toLocaleString());
  //   } else {
  //     dispatch(setAlert(res.data.message, "danger"));
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
};

export const getHistoryOneWallet = gameAccount => async dispatch => {
  await axios
    .get(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }credit/historycount/${gameAccount}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
    .then(res => {
     
      if (res.data.code === '999999') {
        dispatch({
          type: GET_CREDIT_HISTORY_ONE_CREDIT,
          payload: res.data,
        });
      } else {
        dispatch(setAlert(res.data.message, 'danger'));
      }
    });
};
