import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Alert from './components/layout/alert';
import Navbar from './components/layout/Navbar';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import HistoryWallet from './components/wallet/HistoryWallet';
import Landing from './components/layout/Landing';
import Setting from './components/setting/Mainsetting';
import Deposit from './components/tranferPage/Deposit';
import Withdraw from './components/tranferPage/Withdraw';
import LandingOther from './components/landing/Landing';
// import TestPage from './components/auth/testPage';
// import ErrorPage from './components/errorPage/ErrorPage';
// import Register from './components/register/Register';

import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './Actions/AuthAction';

import PrivateRoute from './components/routing/PrivateRoute';
import setAuthToken from './utils/setAuthToken';

import MobileBar from './components/layout/mobilebar/mobilebar';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <Provider store={store}>
        <Router>
          {/* {localStorage.token && <Navbar />} */}
          <div style={{ marginTop: '90px' }}>
            <Fragment>
              <Alert />
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/landing/:t/:key" component={LandingOther} />
                {/* <Route exact path="/register" component={Register} /> */}
                {/* <Route exact path="/login" component={Login} /> */}
                <Route exact path="/login" component={LoginContainer} />
                <PrivateRoute component={DefaultContainer} />
                {/* <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/history" component={HistoryWallet} /> */}
              </Switch>
            </Fragment>
          </div>
          <div style={{ marginTop: '65px' }}>
            {/* {localStorage.token && <MobileBar />} */}
          </div>
        </Router>
      </Provider>
    </div>
  );
};

const LoginContainer = () => (
  <div className="container">
    <Route path="/login" component={Login} />
    {/* <Route path="/test" component={TestPage} /> */}
  </div>
);

const DefaultContainer = () => (
  <div>
    <Navbar />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />
    <PrivateRoute exact path="/history" component={HistoryWallet} />
    <PrivateRoute exact path="/setting" component={Setting} />
    <PrivateRoute exact path="/deposit" component={Deposit} />
    <PrivateRoute exact path="/withdraw" component={Withdraw} />
    <MobileBar />
  </div>
);

export default App;
