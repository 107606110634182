import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBankAccount } from '../../Actions/BankAction';
import { gameForWithdraw, getGame } from '../../Actions/GameAction';
import * as device from 'react-device-detect';

class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txtMoney: '',
      txtAccountNumber: '',
      rdBank: '',
    };
  }
  componentDidMount() {
    this.props.getBankAccount();
    this.props.getGame();
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.state.txtMoney < 100) {
      return <></>
    } else {
      let deviceType = device.deviceType;
      if (deviceType === 'browser') {
        deviceType = 'computer';
      }

      const txtDetail = {
        memberId: this.props?.game?._id,
        txtUser: this.props?.game?.gameAccounts[0].gameUsername,
        systemName: this.props?.game?.gameAccounts[0].systemName,
        webGroup: this.props?.game?.gameAccounts[0].webGroup,
        txtMoney: this.state.txtMoney,
        rdBank: this.props?.bankCostomer?.bankName,
        txtAccountNumber: this.props?.bankCostomer?.bankAccountNumber,
        bankAccountNumber: this.props?.bankCostomer?.bankAccountNumber.substring(
          this.props?.bankCostomer?.bankAccountNumber.length - 4,
          this.props?.bankCostomer?.bankAccountNumber.length,
        ),
        txtName: this.props?.bankCostomer?.nameInBankApp,
        clientIp: this.props.ip,
        clientDevice: deviceType,
        txtPhone: this.props?.auth?.jwtExtraction?.username,
      };
      this.props.gameForWithdraw(txtDetail);
      // console.log(txtDetail);
    }

  };

  render() {
    // console.log(this.props.step);
    // { console.log(this.props) }
    return (
      <div className="container-fluid">
        <form onSubmit={e => this.onSubmit(e)}>
          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-12 text-center">
                  <h2>ระบบถอนเงิน ( ถอนขั้นต่ำ 100 บาท )</h2>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div
                  className="col-6 text-center border py-2"
                  style={{ borderRadius: '15px' }}
                >
                  <h4>ยอดเงินคงเหลือ เครดิต</h4>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row mt-4">
                  <div className="col-3 col-md-3 p-2">ชื่อบัญชี</div>
                  <div
                    className="col-9 col-md-9 p-2 border"
                    style={{ background: '#F4F6F6' }}
                  >
                    {this.props?.bankCostomer?.nameInBankApp}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3 col-md-3 p-2">ธนาคาร-เลขบัญชี</div>
                  <div
                    className="col-9 col-md-9 border p-2"
                    style={{ background: '#F4F6F6' }}
                  >
                    {this.props?.bankCostomer?.bankName} -{' '}
                    {this.props?.bankCostomer?.bankAccountNumber}
                  </div>
                </div>
                <hr />
                <div className="row">
                  {/* <div
                className="col-12 border py-3 text-center mt-2"
                style={{ borderRadius: '15px', background: '#F7DC6F' }}
              >
                <h3>ทำรายการแจ้งถอน</h3>
              </div> */}
                </div>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-2">จำนวนเงิน</div>
                    <input
                      type="number"
                      required={true}
                      name={'txtMoney'}
                      value={this.state.txtMoney}
                      className="form-control col-10"
                      placeholder="จำนวนเงิน"
                      aria-label="txtMoney"
                      aria-describedby="basic-addon1"
                      onChange={e => this.onChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <button
                  type="submit button"
                  className="btn btn-info col-12"
                  data-toggle="modal"
                  data-target="#exampleModalWithdraw"
                >
                  เเจ้งถอนเงิน
                </button>
              </div>
            </div>
            <div className="col-12 col-md-3"></div>
          </div>
        </form>
        <div
          className="modal fade"
          id="exampleModalWithdraw"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalWithdrawLabel"
          aria-hidden="true"
        >
          {this.state.txtMoney < 100 ? (
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalWithdrawLabel">
                    การแจ้งถอนล้มเหลว
                </h5>
                </div>
                <div>
                  <div className="modal-body">
                    <div className="text-center text-danger">
                      <h5>*กรณาใส่จำนวนเงินอย่างน้อย100บาทค่ะ</h5>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary col-12"
                      data-dismiss="modal"
                    >
                      ปิด
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalWithdrawLabel">
                      แจ้งถอน
                </h5>
                  </div>
                  {this.props.tranGameWithdraw ? (
                    <div>
                      <div className="modal-body">
                        <div className="text-center">
                          ท่านได้ทำรายการเรียบร้อยแล้ว กรุณารอ ภายใน{' '}
                          <ins>15-30 วินาที</ins> หรือหากเกิน 10 นาที กรุณาติดต่อ
                      Callcenter
                    </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary col-12"
                          onClick={() => window.location.reload(false)}
                        >
                          เสร็จสิ้น
                    </button>
                      </div>
                    </div>
                  ) : (
                      <div className="modal-body">รอสักครู่ระบบกำลังทำรายการ</div>
                    )}
                </div>
              </div>
            )}

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  game: state.game.dataGame?.datas,
  tranGameWithdraw: state.game?.tranGameWithdraw,
  bankCostomer: state.bank?.dataBankWithdraw?.datas?.bankAccounts[0],
  ip: state.auth.ip,
});

export default connect(mapStateToProps, {
  gameForWithdraw,
  getBankAccount,
  getGame,
})(Withdraw);
