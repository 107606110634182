import React, { Component } from 'react';

class DoorMobile extends Component {
  render() {
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                ทางเข้า MOBILE
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(' https://www.sbobet888.com/', '_blank')
                    }
                  >
                    ทางเข้า 1
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(' https://www.sbo.bz/sbobet/', '_blank')
                    }
                  >
                    ทางเข้า 2
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://m.sbobet.tv/web-root/public/login.aspx',
                        '_blank',
                      )
                    }
                  >
                    ทางเข้า 3
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://m.beer789.com/web-root/public/login.aspx',
                        '_blank',
                      )
                    }
                  >
                    ทางเข้า 4
                  </button>
                </li>
                {/* <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://m.currybread.com/web-root/public/login.aspx',
                        '_blank',
                      )
                    }
                  >
                    currybread
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn btn-info col-12"
                    onClick={() =>
                      window.open(
                        'http://m.onlinesbobet.com/web-root/public/login.aspx',
                        '_blank',
                      )
                    }
                  >
                    onlinesbobet
                  </button>
                </li> */}
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DoorMobile;
