import React, { Component } from 'react';

class Spoil extends Component {
  render() {
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                ทีเด็ด ฟุตบอล !!!
              </div>
              <ui className="list-group list-group-flush color-hover">
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn col-12"
                    onClick={() =>
                      window.open('https://www.sbo.bz/tded/', '_blank')
                    }
                    style={{
                      background: 'linear-gradient(120deg, #3498db, #8e44ad)',
                    }}
                  >
                    sbo.bz
                  </button>
                </li>
                <li className="list-group-item text-muted" type="text">
                  <button
                    className="btn col-12"
                    onClick={() =>
                      window.open(
                        'https://www.sbobet888.com/SBOBET-Tded/',
                        '_blank',
                      )
                    }
                    style={{
                      background: 'linear-gradient(120deg, #3498db, #8e44ad)',
                    }}
                  >
                    sbobet888
                  </button>
                </li>
              </ui>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spoil;
