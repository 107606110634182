import axios from 'axios';
import { CHANGEPASSWORD_SUCCESS, CHANGEPASSWORD_FAIL } from './types';

import { setAlert } from './AlertAction';

export const changePass = (oldPassword, newPassword) => async dispatch => {
  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  try {
    await axios.post(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }auth/changePass`, body, config).then(res => {
      if (res.data.code === '999999') {
        // console.log('เข้ามาแล้วนะจ๊ะ');
        dispatch({
          type: CHANGEPASSWORD_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert(res.data.message, 'success'));
      } else {
        dispatch(setAlert(res.data.message, 'danger'));
      }
    });
  } catch (err) {
    dispatch({
      type: CHANGEPASSWORD_FAIL,
    });
  }
};
