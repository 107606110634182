import React, { Component } from 'react';

import Toolbar from '../layout/toolbar/toolbar';
import SideDrawer from '../layout/sideDrawer/sideDrawer';
import Backdrop from '../layout/backdrop/backdrop';

class Navbar extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backDrop;

    if (this.state.sideDrawerOpen) {
      backDrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div>
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />{' '}
        <SideDrawer
          show={this.state.sideDrawerOpen}
          click={this.backdropClickHandler}
        />
        {backDrop}
      </div>
    );
  }
}

export default Navbar;

// import React from 'react';
// import clsx from 'clsx';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import HistoryIcon from '@material-ui/icons/History';
// import SettingsIcon from '@material-ui/icons/Settings';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import { red } from '@material-ui/core/colors';
// import { Link } from 'react-router-dom';

// const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   appBar: {
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: drawerWidth,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   hide: {
//     display: 'none',
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,

//   },
//   drawerPaper: {
//     width: drawerWidth,
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//   },
//   drawerHeader: {
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',

//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: -drawerWidth,
//   },
//   contentShift: {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   },
// }));

// export default function PersistentDrawerLeft() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);

//   const [selectedIndex, setSelectedIndex] = React.useState(1);
//     const handleListItemClick = (event, index) => {
//         setSelectedIndex(index);
//     };

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div className={classes.root}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         className={clsx(classes.appBar, {
//           [classes.appBarShift]: open,
//         })}
//       >
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             className={clsx(classes.menuButton, open && classes.hide)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap>
//             Wallet System v1.0
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         className={classes.drawer}
//         // variant="persistent"
//         anchor="left"
//         open={open}
//         classes={{
//           paper: classes.drawerPaper,
//         }}
//       >
//         <div className={classes.drawerHeader}>
//         Wallet System v1.0
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//           </IconButton>
//         </div>
//         <Divider />
//         <List component="nav" aria-label="main mailbox folders" >
//                 <ListItem
//                     button
//                     selected={selectedIndex === 0}
//                     onClick={(event) => handleListItemClick(event, 0)}
//                     component={Link}
//                     to ={"/dashboard"}
//                 >
//                     <ListItemIcon>
//                         <DashboardIcon />
//                     </ListItemIcon>
//                     <ListItemText primary="Dashboard" />
//                 </ListItem>
//                 <ListItem
//                     button
//                     selected={selectedIndex === 1}
//                     onClick={(event) => handleListItemClick(event, 1)}
//                     component={Link}
//                     to ={"/history"}
//                 >
//                     <ListItemIcon>
//                         <HistoryIcon />
//                     </ListItemIcon>
//                     <ListItemText primary="History" />
//                 </ListItem>
//                 <ListItem
//                     button
//                     selected={selectedIndex === 2}
//                     onClick={(event) => handleListItemClick(event, 2)}
//                     component={Link}
//                     to ={"/"}
//                 >
//                     <ListItemIcon>
//                         <AccountBalanceWalletIcon />
//                     </ListItemIcon>
//                     <ListItemText primary="Transaction" />
//                 </ListItem>
//                 <ListItem
//                     button
//                     selected={selectedIndex === 3}
//                     onClick={(event) => handleListItemClick(event, 3)}
//                     component={Link}
//                     to ={"/"}
//                 >
//                     <ListItemIcon>
//                         <SettingsIcon />
//                     </ListItemIcon>
//                     <ListItemText primary="Settings" />
//                 </ListItem>

//                 <ListItem
//                     button
//                     selected={selectedIndex === 4}
//                     onClick={(event) => handleListItemClick(event, 4)}
//                     component={Link}
//                     to ={"/"}
//                 >
//                     <ListItemIcon>
//                         <ExitToAppIcon />
//                     </ListItemIcon>
//                     <ListItemText primary="Exit" />
//                 </ListItem>
//             </List>
//             <Divider />
//       </Drawer>
//     </div>
//   );
// }
