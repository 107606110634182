import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  GET_IP_SUCCESS,
} from './types';

import { setAlert } from './AlertAction';

import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async dispatch => {
  // console.log(localStorage.token);
  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(
      `${
        process.env.REACT_APP_API_PREFIX
          ? process.env.REACT_APP_API_PREFIX
          : '/api/'
      }auth/authTest`,
      config,
    );
    if (res.data.code === '999999') {
      if (!localStorage.token) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
        dispatch(getIP());
      }
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
      dispatch(getIP());
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    dispatch(getIP());
  }
};

//Get Ip
export const getIP = () => async dispatch => {
  try {
    const res = await axios.get(
      'https://ibank.allinonebet.fun/ipp/',
    );
    if (res.data !== undefined) {
      // console.log(res.data.ip);
      dispatch({
        type: GET_IP_SUCCESS,
        payload: res.data.ip,
      });
    }
  } catch (error) {
    // console.log(error);
  }
};

// Login User

export const login = (username, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    username: username,
    password: password,
    systemName: 'sbo',
  };

  await axios
    .post(
      `${
        process.env.REACT_APP_API_PREFIX
          ? process.env.REACT_APP_API_PREFIX
          : '/api/'
      }auth/generateToken`,
      body,
      config,
    )
    .then(res => {
      // console.log(process.env)
      if (res.data.code === '999999') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        dispatch(loadUser());
      } else {
        dispatch(setAlert(res.data.message, 'danger'));
      }
    });
};

// Logout / Clear Profile
export const logout = () => async dispatch => {
  await dispatch({ type: LOGOUT });
  window.location.href = await 'https://www.sbo.bz/';
  // dispatch(loadUser());
  // window.location.reload(false);
};

export const registration = tempDetail => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { tempDetail: tempDetail };

  await axios
    .post(
      `${
        process.env.REACT_APP_API_PREFIX
          ? process.env.REACT_APP_API_PREFIX
          : '/api/'
      }auth/registration`,
      body,
      config,
    )
    .then(res => {
      console.log(res);
      if (res.data.code === '999999') {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch(setAlert('ท่านทำรายการไม่สำเร็จ', 'danger'));
      }
    });
};

// test
// export const testAPI = () => async () => {
//   try {
//     await axios.get(`/api/auth/testAPI`).then((res) => {
//       console.log("testAPI success", res);
//     });
//   } catch (error) {
//     console.log("errorrrrrrr!!!!!!");
//   }
// };
