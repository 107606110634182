import React from 'react';
import DrawerToggleButton from '../sideDrawer/drawerToggleButton';
import './toolbar.css';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { logout } from '../../../Actions/AuthAction';

const toolbar = props => (
  <div className="toolbar">
    <nav className="toolbar_navigation navbar navbar-expand-lg">
      <div className="toolbar_toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div>
        <Link className="toolbar_logo navbar-brand text-white" to="/dashboard">
          SBO-BZ
        </Link>
      </div>

      <div className="spacer" />
      <div className="text-white mr-3">
        {props.auth.user && props.auth.user.jwtExtraction.name_TH}
      </div>
      {/* <div className="toolbar_navigation-items">
                <ul className='text-white'>
                    <li className="nav-item">
                        <Link to="/dashboard"><img src={mainLOGO} className="mr-2"></img>Wallet</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/history"><img src={historyLOGO} className="mr-2"></img>History</Link>
                    </li>
                    <li className="nav-item ">
                        <Link to="/#" onClick={props.logout} ><img src={logoutLOGO} className="mr-2"></img>logout</Link>
                    </li>
                </ul>
            </div> */}
    </nav>
  </div>
);

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(toolbar);
