import {
  GET_BANK_SUCCESS,
  GET_BANK_FAIL,
  GET_BANK_WITHDRAW_SUCCESS,
  GET_BANK_WITHDRAW_FAIL,
} from "../Actions/types";

const initialState = {
  dataBank: null,
  dataBankWithdraw: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BANK_SUCCESS:
      return { ...state, dataBank: payload };
    case GET_BANK_WITHDRAW_SUCCESS:
      return { ...state, dataBankWithdraw: payload };
    case GET_BANK_FAIL:
      return { ...state, dataBank: payload };
    case GET_BANK_WITHDRAW_FAIL:
      return { ...state, dataBankWithdraw: payload };
    default:
      return state;
  }
}
