import axios from 'axios';
import {
  OTP_SUCCESS,
  OTP_FAIL,
  OTP_VALIDATION_SUCCESS,
  OTP_VALIDATION_FAIL,
} from './types';

// import { setAlert } from './AlertAction';

export const registerOTP = applicant => async dispatch => {
  const body = {
    applicant: applicant,
    serviceName: 'sbo',
    group: 'guia'
  };
  try {
    await axios.post(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }otp/register`, body).then(res => {
      // console.log(res.data);
      if (res.data.code === '999999') {
        // console.log('เข้ามาแล้วนะจ๊ะ');
        dispatch({
          type: OTP_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: OTP_SUCCESS,
          payload: res.data
        });
        dispatch({
          type: OTP_FAIL,
          payload: null
        })
      }
    });
  } catch (err) {
    // console.log(err);
  }
};

export const validateOTP = (applicant, otp) => async dispatch => {
  const body = {
    applicant: applicant,
    otp: otp,
    group: 'guia'
  };
  try {
    await axios.post(`${process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '/api/' }otp/validate`, body).then(res => {
      console.log(res);
      if (res.data.code === '999999' || res.data.code === '1EKaD5w-00') {
        dispatch({
          type: OTP_VALIDATION_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: OTP_VALIDATION_FAIL,
        });
      }
    });
  } catch (err) {
    // console.log(err);
  }
};

export const clearValidateOTP = () => async dispatch => {
  await dispatch({
    type: OTP_VALIDATION_FAIL,
  });
};
