import React, { Component } from 'react';
import ChangePass from './ChangePass';

class Mainsetting extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-3">
            <div className="card">
              <div className="card-header text-muted font-weight-bold text-uppercase ">
                Change Password
              </div>
              <ChangePass />
            </div>
          </div>
          <div className="col-122 col-md-1"></div>
        </div>
      </div>
    );
  }
}
export default Mainsetting;
