import {
  GET_GAMES_SUCCESS,
  GET_GAMES_FAIL,
  POST_GAME_DEPOSIT_SUCCESS,
  POST_GAME_WITHDRAW_SUCCESS,
  GET_CHECK_LATEST_TASK_DEPOSIT_SUCCESS,
  GET_CHECK_LATEST_TASK_DEPOSIT_WAITING,
  GET_CHECK_LATEST_TASK_DEPOSIT_FAIL,
} from '../Actions/types';

const initialState = {
  dataGame: null,
  tranGame: null,
  checkDeposit: null,
  step: 0,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GAMES_SUCCESS:
      return { ...state, dataGame: payload };
    case POST_GAME_WITHDRAW_SUCCESS:
      return { ...state, tranGameWithdraw: payload };
    case POST_GAME_DEPOSIT_SUCCESS:
      return { ...state, tranGame: payload, step: 1 };
    case GET_GAMES_FAIL:
      return { ...state, dataGame: payload };
    case GET_CHECK_LATEST_TASK_DEPOSIT_SUCCESS:
      return { ...state, step: 0 };
    case GET_CHECK_LATEST_TASK_DEPOSIT_WAITING:
      return { ...state, step: 0 };
    case GET_CHECK_LATEST_TASK_DEPOSIT_FAIL:
      return { ...state, step: 0 };
    default:
      return state;
  }
}
